import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/home-Administrator-SwissThai",
    name: "home-Administrator-SwissThai",
    component: () => import("../components/admin_views/Homepage.vue"),
  },
  {
    path: "/userinsystem",
    name: "userinsystem",
    component: () => import("../components/admin_views/UserInSystem.vue"),
  },
  {
    path: "/tutorials/:key",
    name: "tutorial-details",
    component: () => import("../components/admin_views/UserLoginDetial.vue"),
  },
  {
    path: "/useritemcurrent",
    name: "useritemcurrent",
    component: () => import("../components/admin_views/UserItemCurrent.vue"),
  },
  {
    path: "/add",
    name: "add",
    component: () => import("../components/admin_views/AddUserLogin.vue"),
  },
  //*********************************************************************************************/
  //*********************************************************************************************/
  {
    path: "/home-EdenRock-Admin",
    name: "home-EdenRock-Admin",
    component: () => import("../components/edenrock_views/Homepage.vue"),
  },
  {
    path: "/03_tutorial-details/:key",
    name: "03_tutorial-details",
    component: () => import("../components/edenrock_views/UserGroupDetial.vue"),
  },
  {
    path: "/03_editgroup-details/:key",
    name: "03_editgroup-details",
    component: () => import("../components/edenrock_views/EditGroupDetial.vue"),
  },
  {
    path: "/03_usergrouplist",
    name: "03_usergrouplist",
    component: () => import("../components/edenrock_views/UserGroupList.vue"),
  },
  {
    path: "/03_add",
    name: "03_add",
    component: () => import("../components/edenrock_views/AddUserGroup.vue"),
  },
  {
    path: "/03_addGroup",
    name: "03_addGroup",
    component: () => import("../components/edenrock_views/AddGroupName.vue"),
  },
  {
    path: "/03_useritemcurrent",
    name: "03_useritemcurrent",
    component: () => import("../components/edenrock_views/UserItemCurrent.vue"),
  },
  {
    path: "/03_userinsystem",
    name: "03_userinsystem",
    component: () => import("../components/edenrock_views/UserInSystem.vue"),
  },
  {
    path: "/03_useritemselect",
    name: "03_useritemselect",
    component: () => import("../components/edenrock_views/UserItemSelect.vue"),
  },
  {
    path: "/03_usergroupinsystem",
    name: "03_usergroupinsystem",
    component: () => import("../components/edenrock_views/UserGroupInSystem.vue"),
  },
  {
    path: "/03_userselectgroupinsystem",
    name: "03_userselectgroupinsystem",
    component: () => import("../components/edenrock_views/UserSelectGroupInSystem.vue"),
  },
  //*********************************************************************************************/
  //*********************************************************************************************/
    //*********************************************************************************************/
  //*********************************************************************************************/
  {
    path: "/home-WhitePod-Admin",
    name: "home-WhitePod-Admin",
    component: () => import("../components/whitepod_views/Homepage.vue"),
  },
  {
    path: "/04_tutorial-details/:key",
    name: "04_tutorial-details",
    component: () => import("../components/whitepod_views/UserGroupDetial.vue"),
  },
  {
    path: "/04_editgroup-details/:key",
    name: "04_editgroup-details",
    component: () => import("../components/whitepod_views/EditGroupDetial.vue"),
  },
  {
    path: "/04_usergrouplist",
    name: "04_usergrouplist",
    component: () => import("../components/whitepod_views/UserGroupList.vue"),
  },
  {
    path: "/04_add",
    name: "04_add",
    component: () => import("../components/whitepod_views/AddUserGroup.vue"),
  },
  {
    path: "/04_addGroup",
    name: "04_addGroup",
    component: () => import("../components/whitepod_views/AddGroupName.vue"),
  },
  {
    path: "/04_useritemcurrent",
    name: "04_useritemcurrent",
    component: () => import("../components/whitepod_views/UserItemCurrent.vue"),
  },
  {
    path: "/04_userinsystem",
    name: "04_userinsystem",
    component: () => import("../components/whitepod_views/UserInSystem.vue"),
  },
  {
    path: "/04_useritemselect",
    name: "04_useritemselect",
    component: () => import("../components/whitepod_views/UserItemSelect.vue"),
  },
  {
    path: "/04_usergroupinsystem",
    name: "04_usergroupinsystem",
    component: () => import("../components/whitepod_views/UserGroupInSystem.vue"),
  },
  {
    path: "/04_userselectgroupinsystem",
    name: "04_userselectgroupinsystem",
    component: () => import("../components/whitepod_views/UserSelectGroupInSystem.vue"),
  },
  //*********************************************************************************************/
  //*********************************************************************************************/
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "*",
    redirect: "/login", // page not found
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
