//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE = "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE = "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

// export const apiUrl = "http://localhost:8081/api/v2";
// export const imageUrl = "http://localhost:8081/images";
// export const apiUrl = "http://localhost:3600/api/v2";
// export const imageUrl = "http://localhost:3600/images";
export const apiUrl = "/api/v2";
export const imageUrl = "/images";

export const server = {
  LOGIN_URL: `login`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `product`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `report`,
  TOKEN_KEY: `token`,
  USERNAME: `username`,
  ISADMIN: true,
  FDB_NAME: `DB`,
  PUBLISHED_MODE: false,
};