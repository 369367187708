import database from "../firebase";
const db = database.ref("/User3/Userlogin");
class UserLoginService {
  findAll() {
    return db;
  }
  async findByUser(result) {
    let _tutorials = [];
    await db.orderByChild("username")
      .equalTo(result)
      .once("value", (snapshot) => {
        snapshot.forEach((item) => {
          let key = item.key;
          let data = item.val();
          _tutorials.push({
            key: key,
            id: data.id,
            username: data.username,
            password: data.password,
            customerName: data.customerName,
            groupId: data.groupId,
            level: data.level,
            title: data.title,
            description: data.description,
            published: data.published,
          });
        });
      });
    console.log(`ข้อมูล findByUser : ${JSON.stringify(_tutorials)}`);
    //return { data: _tutorials };
    let promise = new Promise(function (resolve,reject) {
        if(resolve){
            resolve({ data: _tutorials });
            console.log("resolve");
        }else if(reject){
            reject({data: []})
            console.log("reject");
        }
    });
    return promise;
  }
  async findByKey(result) {
    let _tutorials = [];
    await db
      .orderByKey()
      .equalTo(result)
      .once("value", (snapshot) => {
        snapshot.forEach((item) => {
          let key = item.key;
          let data = item.val();
          _tutorials.push({
            key: key,
            id: data.id,
            username: data.username,
            password: data.password,
            customerName: data.customerName,
            groupId: data.groupId,
            level: data.level,
            title: data.title,
            description: data.description,
            published: data.published,
          });
        });
      });
    let promise = new Promise(function (resolve) {
      resolve({ data: _tutorials });
    });
    return promise;
  }
}
export default new UserLoginService();
