<template>
  <v-app>
    <v-container fluid>
      <Header v-if="$store.state.isLogged" />
    </v-container>
    <v-container fluid>
      <Content />
    </v-container>
    <v-container fluid>
      <Menu v-if="$store.state.isLogged" />
    </v-container>
  </v-app>
</template>

<script>
import Header from "@/components/core/Header.vue";
import Content from "@/components/core/Content.vue";
import Menu from "@/components/core/Menu.vue";

export default {
  name: "App",
  methods: {},
  components: {
    Header,
    Content,
    Menu,
  },
  computed: {},
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch({ type: "restoreLogin" });
  },
};
</script>
<style>
main.v-main{
    padding-left: 70px !important;
    /* background: rgba(54, 15, 228, 0.6); */
}
</style>
