import firebase from '@firebase/app'
import '@firebase/database'

//Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAMDrRw3wbLQE4xeMX6eHTxDZQjEtgAhW0",
    authDomain: "ultra-bio-ozone.firebaseapp.com",
    databaseURL: "https://ultra-bio-ozone.firebaseio.com",
    projectId: "ultra-bio-ozone",
    storageBucket: "ultra-bio-ozone.appspot.com",
    messagingSenderId: "773296169407",
    appId: "1:773296169407:web:e18e5bd62d01175b77810a",
    measurementId: "G-N9X9MMP8Z7"
  };
//Your web app's Firebase configuration
  // const firebaseConfig = {
  //   apiKey: "AIzaSyC8zGRzmeAej3VZq5wOvvtoQmTZzPxH7yA",
  //   authDomain: "vue3-firebase-39d29.firebaseapp.com",
  //   databaseURL: "https://vue3-firebase-39d29-default-rtdb.asia-southeast1.firebasedatabase.app",
  //   projectId: "vue3-firebase-39d29",
  //   storageBucket: "vue3-firebase-39d29.appspot.com",
  //   messagingSenderId: "1001326406441",
  //   appId: "1:1001326406441:web:a018204b2bb4150ace6538"
  // };
//Your web app's Firebase configuration
// var firebaseConfig = {
//     apiKey: "AIzaSyDjJHkuizzmHW3mAeVzG5ctYJZDcyO4D-8",
//     authDomain: "fir-demo-ff3cd.firebaseapp.com",
//     databaseURL: "https://fir-demo-ff3cd-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "fir-demo-ff3cd",
//     storageBucket: "fir-demo-ff3cd.appspot.com",
//     messagingSenderId: "236422180197",
//     appId: "1:236422180197:web:bcae29741605544745058c"
// };
firebase.initializeApp(firebaseConfig);
// Get a reference to the database service
var database = firebase.database();
//export default firebase.database();
export default database;