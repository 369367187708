<template>
  <div id="header">
    <v-main>
      <v-container grid-list-xs>
        <v-card class="overflow-hidden">
          <v-app-bar
            absolute
            color="#6A76AB"
            dark
            shrink-on-scroll
            prominent
            src="https://picsum.photos/1920/1080?random"
            fade-img-on-scroll
            scroll-target="#scrolling-techniques-3"
          >
            <template v-slot:img="{ props }">
              <v-img
                v-bind="props"
                gradient="to top right, rgba(100,160,200,.4), rgba(25,32,72,.7)"
              ></v-img>
            </template>

            <v-btn icon @click="onClickLogOff">
              <v-icon>mdi-export</v-icon>
            </v-btn>
             <span>{{ $store.getters["username"] | capitalize }}</span>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-app-bar-title>UBO Firebase</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-app-bar>
          <v-sheet
            id="scrolling-techniques-3"
            class="overflow-y-auto"
            max-height="500"
          >
            <v-container style="height: 216px"></v-container>
          </v-sheet>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Header",
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
  },
  methods: {
    onClickLogOff() {
      this.$store.dispatch("doLogout");
    },
  },
};
</script>

<style scaped>
.v-app-bar-title__content {
  width: 250px;
}
</style>
