//import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";
import router from "@/router";
import store from "@/store";
import UserLoginService from "./UserLoginService";

const isLoggedIn = () => {
  let token = localStorage.getItem(server.TOKEN_KEY);
  console.log("Token : ", token);
  return token != null;
};

async function logoff() {
  console.log(`ก่อนออกจากระบบ`);
  console.log(`isAdmin : ${store.getters['isAdmin']}`);
  console.log(`DB Name : ${store.getters['isDBname']}`);
  console.log(`Publish : ${store.getters['isPublished']}`);
  localStorage.removeItem(server.TOKEN_KEY);
  await store.dispatch("setAdmin" , { data: false });
  await store.dispatch("setDBname", { data: null });
  await store.dispatch("setPublished",{ data: false})
  router.push("/login").catch(() => {});
  console.log(`ออกจากระบบเสร็จแล้ว`);
  console.log(`isAdmin : ${store.getters['isAdmin']}`);
  console.log(`DB Name : ${store.getters['isDBname']}`);
  console.log(`Publish : ${store.getters['isPublished']}`);
}

async function login(values) {
  console.log(`ข้อมูลจากการ Login : ${JSON.stringify(values)}`);
  let result = await UserLoginService.findByUser(values.username);
  console.log(`ข้อมูล result : ${JSON.stringify(result)}`);
  //let result = {data:{result:"ok"}}; //await httpClient.post(server.LOGIN_URL, values);
  //สำคัญ Username ต้องตั้งค่าไม่ซ้ำกัน สำหรับการเขียนในครั้งนี้
  if (result.data.length == 1) {
    if (result.data[0].password === values.password) {
      console.log(`การเข้าระบบโอเค`);
      localStorage.setItem(server.USERNAME, values.username);
      //localStorage.setItem(server.TOKEN_KEY, result.data.token);
      localStorage.setItem(server.TOKEN_KEY, "isLogin_success");
      localStorage.setItem(server.FDB_NAME,result.data[0].customerName);
      localStorage.setItem(server.PUBLISHED_MODE,result.data[0].published);

      console.log(`Datbase Name ---->: ${result.data[0].customerName}`);
      console.log(`Datbase Published ---->: ${result.data[0].published}`);

      await store.dispatch("setDBname", { data: result.data[0].customerName });
      await store.dispatch("setPublished",{ data: result.data[0].published });
      if (result.data[0].level === "admin") {
        localStorage.setItem(server.ISADMIN,{data:true});
        await store.dispatch("setAdmin", { data: true });
        alert(`เข้าสู่โหมดของ Administrator`);
        router.push('/home-Administrator-SwissThai');
        return true;
      }
      if (store.getters["isDBname"]) {
        switch (store.getters["isDBname"]) {
          case "Eden Rock":
            alert(`เข้าสู่โหมดของ Eden Rock`);
            console.log(`เข้าสู่โหมดของ Eden Rock`);
            router.push("/home-EdenRock-Admin");
            break;
          case "Bouissou":
            alert(`เข้าสู่โหมดของ Bouissou`);
            console.log(`เข้าสู่โหมดของ Bouissou`);
            //router.push('/userloginlist');
            break;
          case "Whitepod":
            alert(`เข้าสู่โหมดของ Whitepod`);
            console.log(`เข้าสู่โหมดของ Whitepod`);
            router.push("/home-WhitePod-Admin");
            break;
          default:
            break;
        }
        return true;
      }
    } else {
      alert("Password is not correct!");
      return false;
    }
  } else {
    alert("Username is not correct!");
    return false;
  }
}

async function register(values) {
  console.log(`ข้อมูลจากการ Register : ${values}`);
  let result = { data: { result: "ok" } }; //await httpClient.post(server.REGISTER_URL, values);
  if (result.data.result == "ok") {
    router.go(-1);
  } else {
    alert(JSON.stringify(result));
  }
}

export default {
  logoff,
  login,
  register,
  isLoggedIn,
};
