<template>
  <div id="main_menu">
    <v-card
    class="mx-auto"
    >
      <v-navigation-drawer
      app
      permanent
      expand-on-hover
      dark
      v-model="drawer"
      >
        <!-- <router-link to="" exact>
        <v-img src="@/assets/swisslogo.jpg" alt="" width="100%" />
      </router-link> -->
        <div>
          <v-img
            src="@/assets/swisslogo.jpg"
            alt=""
            width="100%"
            @click.stop="drawer = !drawer"
          />
        </div>
        <!-- <span>{{ $store.getters["username"] | capitalize }}</span> -->
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <v-list nav v-if="this.$store.getters['isAdmin']" shaped>
          <v-list-item-group v-model="selectedMenu" mandatory color="primary">
            <v-list-item
              class="tile"
              v-for="([icon, title, route], index) in menus"
              :key="index"
              @click="onClickMenu(route)"
            >
              <v-list-item-icon>
                <v-icon color="white">{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list
          nav
          v-if="
            this.$store.getters['isDBname'] == 'Eden Rock' ||
            this.$store.getters['isAdmin']
          "
          shaped
        >
          <v-divider></v-divider>
          <p style="text-align: center; color: Orchid">Eden Rock</p>
          <v-divider></v-divider>
          <v-list-item-group
            v-model="edenrock_selectedMenu"
            mandatory
            color="primary"
          >
            <v-list-item
              class="tile"
              v-for="([icon, title, route], index) in menus_edenrock"
              :key="index"
              @click="onClickMenu(route)"
            >
              <v-list-item-icon>
                <v-icon color="white">{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-list
          nav
          v-if="
            this.$store.getters['isDBname'] == 'Whitepod' ||
            this.$store.getters['isAdmin']
          "
          shaped
        >
          <v-divider></v-divider>
          <p style="text-align: center; color: Orchid">Whitepod</p>
          <v-divider></v-divider>
          <v-list-item-group
            v-model="whitepod_selectedMenu"
            mandatory
            color="primary"
          >
            <v-list-item
              class="tile"
              v-for="([icon, title, route], index) in menus_whitepod"
              :key="index"
              @click="onClickMenu(route)"
            >
              <v-list-item-icon>
                <v-icon color="white">{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <span v-if="false">{{ selectedMenu }}</span
        ><br />
        <span v-if="false">{{ edenrock_selectedMenu }}</span>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "mainmenu",
  methods: {
    onClickMenu(link) {
      this.$router.push(link).catch((err) => {
        console.log(err);
      });
    },
  },
  async mounted() {
    if (this.$store.getters["isAdmin"]) {
      this.selectedMenu = this.menus.findIndex((menu) => {
        menu[2] == this.$route.path;
        //console.log(this.$route.path);
        //console.log("MENU : ", menu[2]);
      });
      this.edenrock_selectedMenu = this.menus_edenrock.findIndex((menu) => {
        menu[2] == this.$route.path;
        //console.log(this.$route.path);
        //console.log("MENU : ", menu[2]);
      });
      this.whitepod_selectedMenu = this.menus_whitepod.findIndex((menu) => {
        menu[2] == this.$route.path;
        //console.log(this.$route.path);
        //console.log("MENU : ", menu[2]);
      });
      return;
    } else if (this.$store.getters["isDBname"] == "Eden Rock") {
      this.edenrock_selectedMenu = this.menus_edenrock.findIndex((menu) => {
        menu[2] == this.$route.path;
        //console.log(this.$route.path);
        //console.log("MENU : ", menu[2]);
      });
      return;
    } else if (this.$store.getters["isDBname"] == "Whitepod") {
      this.whitepod_selectedMenu = this.menus_whitepod.findIndex((menu) => {
        menu[2] == this.$route.path;
        //console.log(this.$route.path);
        //console.log("MENU : ", menu[2]);
      });
      return;
    }
    // this.selectedMenu_1 = this.menus_edenrock.findIndex((menu) => {
    //   menu[2] == this.$route.path;
    //   console.log(this.$route.path);
    //   console.log("MENU : ", menu[2]);
    // });
  },
  data() {
    return {
      drawer: false,
      selectedMenu: 0,
      edenrock_selectedMenu: 0,
      whitepod_selectedMenu: 0,
      menus: [
        ["mdi-account-supervisor-circle", "Edit User Login", "/home-Administrator-SwissThai"],
        ["mdi-chart-arc", "Report All Group", "/userinsystem"],
        ["mdi-account-clock-outline", "Report Online Status", "/useritemcurrent"],
        ["mdi-chat-alert", "About", "/about"],
      ],
      menus_edenrock: [
        ["mdi-cog-transfer-outline", "Home", "/home-EdenRock-Admin"],
        ["mdi-desktop-tower-monitor", "Report All Group", "/03_userinsystem"],
        ["mdi-chart-arc", "Report By Device", "/03_useritemselect"],
        ["mdi-account-clock-outline", "Report Online Status", "/03_useritemcurrent"],
        [
          "mdi-calendar-clock-outline",
          "Report Group Days",
          "/03_usergroupinsystem",
        ],
        [
          "mdi-chart-areaspline",
          "Report Group Select",
          "/03_userselectgroupinsystem",
        ],
      ],
      menus_whitepod: [
        ["mdi-cog-transfer-outline", "Home", "/home-WhitePod-Admin"],
        //["mdi-desktop-tower-monitor", "Report All Device", "/04_userinsystem"],
        //["mdi-chart-arc", "Report By Device", "/04_useritemselect"],
        ["mdi-account-clock-outline", "Report Online Status", "/04_useritemcurrent"],
        [
          "mdi-calendar-clock-outline",
          "Report Group Days",
          "/04_usergroupinsystem",
        ],
        [
          "mdi-chart-areaspline",
          "Report Group Select",
          "/04_userselectgroupinsystem",
        ],
      ],
    };
  },
  watch: {
    // $route(to) {
    //   this.selectedMenu = this.menus.findIndex((menu) => menu[2] == to.path);
    // },
  },
};
</script>

<style>
.v-list-item-group .v-list-item--active {
  color: #fff !important;
  font-weight: bold;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  opacity: 0.7;
}

.tile {
  color: white;
}

.tile:hover {
  background: green;
}

.tile:active {
  background: #05ab71;
}

nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--mini-variant.v-navigation-drawer--open.v-navigation-drawer--open-on-hover.theme--dark{
    width: 80px !important;
}
</style>
