<template>
  <div id="my_content">
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "My_Content",
};
</script>

<style></style>
