import api from '@/services/api'
import { server } from '@/services/constants'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogged: false,
        username: "",
        isAdmin:false,
        db_name:"",
        isPublished:false,
    },
    getters: {
        isLogin(state) {
            return state.isLogged;
        },
        username(state) {
            return state.username;
        },
        isAdmin(state){
            return state.isAdmin;
        },
        isDBname(state){
            return state.db_name;
        },
        isPublished(state){
            return state.isPublished;
        }
    },
    mutations: {
        SET_LOGGED_IN(state) {
            state.isLogged = true;
        },
        SET_LOGGED_OUT(state) {
            state.isLogged = false;
        },
        SET_USERNAME(state, value) {
            state.username = value;
        },
        SET_ISADMIN(state,value){
            state.isAdmin = value;
        },
        SET_DB_NAME(state,value){
            state.db_name = value;
        },
        SET_ISPUBLISHED(state,value){
            state.isPublished = value;
        }
    },
    actions: {
        restoreLogin({ commit }) {
            if (api.isLoggedIn() == true) {
                let username = localStorage.getItem(server.USERNAME);
                let isAdministrator = localStorage.getItem(server.ISADMIN);
                let db_name  = localStorage.getItem(server.FDB_NAME);
                let published = localStorage.getItem(server.PUBLISHED_MODE);

                commit("SET_LOGGED_IN");
                commit("SET_USERNAME", username);
                commit("SET_ISADMIN", isAdministrator);
                commit("SET_DB_NAME",db_name);
                commit("SET_ISPUBLISHED",published);
            }
        },
        async doLogin({ commit, dispatch }, { username, password }) {
            console.log(`ทำ doLogin : ${username}:${password}`)
            let result = await api.login({ username, password });
            if (result == true) {
                commit("SET_LOGGED_IN");
                commit("SET_USERNAME", username);
            } else {
                console.log("ทำ doLogout");
                dispatch("doLogout", {});
            }
        },
        async doLogout({ commit }) {
            api.logoff();
            commit("SET_LOGGED_OUT");
            commit("SET_USERNAME", "");
        },
        async setAdmin({ commit },{data}){
            commit("SET_ISADMIN",data)
        },
        async setDBname({ commit },{data}){
            commit("SET_DB_NAME",data);
        },
        async setPublished({ commit },{data}){
            commit("SET_ISPUBLISHED",data);
        }
    },
    modules: {
    }
})
