var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"main_menu"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-navigation-drawer',{attrs:{"app":"","permanent":"","expand-on-hover":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',[_c('v-img',{attrs:{"src":require("@/assets/swisslogo.jpg"),"alt":"","width":"100%"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1),(this.$store.getters['isAdmin'])?_c('v-list',{attrs:{"nav":"","shaped":""}},[_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.selectedMenu),callback:function ($$v) {_vm.selectedMenu=$$v},expression:"selectedMenu"}},_vm._l((_vm.menus),function(ref,index){
var icon = ref[0];
var title = ref[1];
var route = ref[2];
return _c('v-list-item',{key:index,staticClass:"tile",on:{"click":function($event){return _vm.onClickMenu(route)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)],1)}),1)],1):_vm._e(),(
          this.$store.getters['isDBname'] == 'Eden Rock' ||
          this.$store.getters['isAdmin']
        )?_c('v-list',{attrs:{"nav":"","shaped":""}},[_c('v-divider'),_c('p',{staticStyle:{"text-align":"center","color":"Orchid"}},[_vm._v("Eden Rock")]),_c('v-divider'),_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.edenrock_selectedMenu),callback:function ($$v) {_vm.edenrock_selectedMenu=$$v},expression:"edenrock_selectedMenu"}},_vm._l((_vm.menus_edenrock),function(ref,index){
        var icon = ref[0];
        var title = ref[1];
        var route = ref[2];
return _c('v-list-item',{key:index,staticClass:"tile",on:{"click":function($event){return _vm.onClickMenu(route)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)],1)}),1)],1):_vm._e(),(
          this.$store.getters['isDBname'] == 'Whitepod' ||
          this.$store.getters['isAdmin']
        )?_c('v-list',{attrs:{"nav":"","shaped":""}},[_c('v-divider'),_c('p',{staticStyle:{"text-align":"center","color":"Orchid"}},[_vm._v("Whitepod")]),_c('v-divider'),_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.whitepod_selectedMenu),callback:function ($$v) {_vm.whitepod_selectedMenu=$$v},expression:"whitepod_selectedMenu"}},_vm._l((_vm.menus_whitepod),function(ref,index){
        var icon = ref[0];
        var title = ref[1];
        var route = ref[2];
return _c('v-list-item',{key:index,staticClass:"tile",on:{"click":function($event){return _vm.onClickMenu(route)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)],1)}),1)],1):_vm._e(),(false)?_c('span',[_vm._v(_vm._s(_vm.selectedMenu))]):_vm._e(),_c('br'),(false)?_c('span',[_vm._v(_vm._s(_vm.edenrock_selectedMenu))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }